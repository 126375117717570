import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Header from './components/Header';

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/services" element={<HeaderRoutes><Services /></HeaderRoutes>} />
        <Route path="/about" element={<HeaderRoutes><About /></HeaderRoutes>} />
        <Route path="/contact" element={<HeaderRoutes><Contact /></HeaderRoutes>} />
      </Routes>
    </Router>
  );
}

const HeaderRoutes = ({ children }) => (
  <>
    <Header />
    {children}
  </>
);

export default AppRouter;