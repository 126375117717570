import React from 'react';


const About = () => {
    return (
      <div className="about-us container">
      <h1 className='title'>About Byteton</h1>
      <div className="about-us-content">
        <div>
        <p className='first-paragraph'>
        At <span style={{color: "#FFC300 "}}>ßyteton Solutions Inc.</span>, we are dedicated to helping organizations optimize their most valuable asset - their people. With our comprehensive suite of HCM solutions, we empower businesses to streamline their HR processes, enhance employee engagement, and drive strategic workforce decisions. With robust features for employee data management, integrated payroll processing, talent acquisition, and engagement tools, our HCM software delivers data-driven insights to make informed workforce decisions. Our dedicated team provides exceptional customer support to help organizations unlock the full potential of their workforce and drive business success.
        </p>
        </div>
        <br />
        <br />
        <div className='background-color'>
        <h1 className='title'>Mission</h1>
        <p className='first-paragraph'>
          Our mission is to empower organizations to optimize their human capital by providing comprehensive, user-friendly Human Capital Management (HCM) solutions that streamline HR processes, enhance employee engagement, and drive strategic workforce decisions. We believe in building strong and long-lasting relationships with our clients,
          and we pride ourselves on delivering top-notch quality and exceptional customer service.
        </p>
        </div>

      </div>
    </div>
    );
  }
  
  export default About;

  