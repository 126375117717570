// import React, { useState } from 'react';
import AppRouter from './AppRouter';

function App() {
  // const [message, setMessage] = useState('');

  // useEffect(() => {
  //   fetch('/api')
  //     .then(response => response.json())
  //     .then(data => setMessage(data.message))
  //     .catch(error => console.log(error));
  // }, []);


  return (
    <div className="App">
      <AppRouter/>
    </div>
  );
}

export default App;

