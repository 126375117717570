import React from 'react';
import './main.css';
const Contact = () => {
    return (
      <div > 
      <div className='contact-content service-padding'>
      <h1 className='contact-title'>Get in Touch!</h1>
      <br />
      <br />
      <p>info@bytetonsolutions.ca</p>

      </div>
     
    </div>
    
    );
  }
  
  export default Contact;