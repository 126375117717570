import React from 'react';
import './main.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className="nav-links">
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/services">Services</Link>
      </li>
      <li>
        <Link to="/about">About Us</Link>
      </li>
      <li>
        <Link to="/contact">Contact Us</Link>
      </li>
    </ul>
  </nav>
  );
};

export default Navigation;