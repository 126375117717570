import React from 'react';
import './main.css';
import bannerImage from '../images/banner.jpg';

import logo from '../Color_logo.svg';
import Navigation from  './navigation'

const HomePage = () => {
  return (
    <header className="headerHome">
    <div className="background-image" style={{ backgroundImage: `url(${bannerImage})` }}></div>
    <div className="header">
    <div className="logo">
          <img src={logo} alt="Logo" className="logo"/>
    </div>
    <Navigation></Navigation>
    </div>
    <div className="text-box">
      <h1 className='large-font'>Transform Your HR & Payroll Operations with Expert Consulting Service</h1>
      <p className='small-font'>At ßyteton Solutions Inc., we offer comprehensive HCM (Human Capital Management) and payroll consulting services tailored to meet the unique needs of your organization. With years of industry expertise, our team of dedicated consultants is committed to delivering top-notch solutions that streamline your HR and payroll processes, optimize efficiency, and empower your workforce.</p>
    </div>
  </header>
  );
}

export default HomePage;