import React from 'react';
import logo from '../Color_logo.svg'
import Navigation from  './navigation'

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" className="logo"/>
      </div>
      <Navigation></Navigation> 
      
    </header>
  );
}

export default Header;
