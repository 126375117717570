import React from 'react';
import './main.css';
const Services = () => {
    return (
      <div className='service'> 
        <div className='service-content service-padding'>
        <h2 >Payroll Management</h2>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
          <li><h4>Integrated Payroll Functionality</h4></li>
          <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
            <li>  We offers a comprehensive payroll management module that is seamlessly integrated into our Human Capital Management (HCM) system, providing a unified platform for managing payroll processes. </li>
          </ul>
          <li> <h4>Accurate and Timely Payroll Processing </h4></li>
            <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
              <li>  Ensures precise and punctual payroll processing, guaranteeing that employees receive their wages on time and accurately reflecting their earnings. </li>
            </ul>
            <li><h4> Reporting and Analytics</h4> </li>
              <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
                <li>  Our payroll management module generates comprehensive reports and analytics, providing valuable insights into payroll costs, trends, and compliance metrics. These reports help organizations monitor payroll expenses, make informed decisions, and meet reporting requirements. </li>
              </ul>
            <li><h4> Administrative Burden Reduction </h4></li>
              <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
                <li>  By automating payroll calculations, tax filings, and report generation, our payroll management module reduces administrative burdens associated with manual data entry, calculations, and paperwork, freeing up HR teams to focus on strategic initiatives. </li>
              </ul>
        </ul>
        </div>

        <div className='background-color ' >
          <div className='service-content'>
        <h2 style={{color: "#FFC300 "}}>Implementation</h2>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
          <li><h4>Assessment</h4></li>
          <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
            <li>  Our expert consultants work closely with your HR and IT teams to conduct a detailed needs assessment. We gain insights into your organization's HR processes, pain points, and objectives to tailor the HCM implementation accordingly. </li>
          </ul>
          <li><h4> Solution Design and Configuration</h4> </li>
            <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
              <li>  Based on the assessment, we design an HCM solution that optimizes your HR processes and fosters a culture of employee engagement. Our team configures the HCM software to suit your organization's unique workflows, policies, and industry standards. </li>
            </ul>
            <li><h4> Go-Live Support and Post-Implementation </h4></li>
              <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
                <li>  During the go-live phase, we provide dedicated support to address any concerns or questions from your team. Our expert consultants are available to guide users and troubleshoot any challenges that may arise.</li>
              </ul>
        </ul>
        </div>
        </div>

        <div className='service-content service-padding'>
        <h2>Continuous Improvement and Optimization Service</h2>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
          <li><h4>Ongoing System Monitoring</h4></li>
          <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
            <li>  We continuously monitor the performance and usage of your HCM system to identify areas for improvement and optimization. </li>
          </ul>
          <li> <h4>Feedback Collection </h4></li>
            <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
              <li>  We actively seek feedback from users to understand their experiences and pain points, enabling us to address issues promptly. </li>
            </ul>
            <li> <h4>Scalability Planning </h4></li>
              <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
                <li>  We work with you to plan for future growth and scalability, ensuring that the HCM system can support your expanding workforce.</li>
              </ul>
              <li> <h4>Strategic HR Consulting </h4></li>
              <ul style={{ listStyleType: 'disc', marginLeft: '40px' }}>
                <li>  We provide strategic HR consulting services to help align your HR practices with organizational objectives and foster continuous improvement.</li>
              </ul>
        </ul>
        </div>
       
      </div>
      

      

      
    );
  }
  
  export default Services;